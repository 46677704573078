import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthCallback from "./AuthCallback";
import LoggedInPage from "./components/LoggedInPage";
import Login from "./components/LoginPage";
import {darkTheme} from "./theme";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {StyledEngineProvider} from "@mui/material";

ReactDOM.createRoot(document.getElementById('root')!).render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/auth/callback" element={<AuthCallback />} />
                    <Route path="/loggedIn" element={<LoggedInPage />} />
                </Routes>
            </Router>
        </ThemeProvider>
    </StyledEngineProvider>
);