import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const hash = window.location.hash;
        const token = new URLSearchParams(hash.substring(1)).get("access_token");

        if (token) {
            localStorage.setItem("twitch_token", token);
            navigate("/loggedIn"); // Redirect back to home
        }
    }, [navigate]);

    return <div>Logging you in...</div>;
};

export default AuthCallback;
