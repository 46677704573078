import { createTheme, ThemeOptions } from '@mui/material/styles';

// Module augmentation for MUI's theme
declare module '@mui/material/styles' {
    // Extend the Palette interface to include background
    interface TypeBackground {
        default: string;
        paper: string;
    }

    interface Palette {
        background: TypeBackground;
    }

    interface PaletteOptions {
        background?: Partial<TypeBackground>;
    }
}

// Define your theme options
const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#BB86FC',
            light: '#C99CFF',
            dark: '#9B67E0',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#03DAC6',
            light: '#66FFF9',
            dark: '#00A896',
            contrastText: '#000000',
        },
        background: {
            default: '#121212',
            paper: '#1E1E1E',
        },
    },
};

// Create the theme
export const darkTheme = createTheme(themeOptions);
