import React from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import {Logo, StyledCard, StyledCardContent, TwitchButton, TwitchIcon} from "./Login.styles";



export default function Login() {
    const handleLogin = () => {
        const clientId = process.env.REACT_APP_TWITCH_CLIENT_ID;
        const redirectUri = process.env.REACT_APP_REDIRECT_URI;
        const oauthUrl = process.env.REACT_APP_TWITCH_OAUTH_URL;
        const scopes = encodeURIComponent('moderation:read chat:edit user:read:moderated_channels moderator:manage:banned_users');

        window.location.href = `${oauthUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=${scopes}`;
    };

    return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                }}
            >
                <StyledCard>
                    <StyledCardContent>
                        <Box sx={{ textAlign: 'center', mb: 4 }}>
                            <img src={'logo.png'} alt="Crossbanhammer Logo" style={{ maxWidth: '100%', height: 'auto' }} />
                        </Box>
                        <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
                            Connect with your Twitch account to start dropping that hammer!
                        </Typography>
                        <TwitchButton
                            fullWidth
                            variant="contained"
                            onClick={handleLogin}
                            size="large"
                            startIcon={<TwitchIcon src="twitchIconWhite.png" alt="Twitch" />}
                        >
                            Connect with Twitch
                        </TwitchButton>
                    </StyledCardContent>
                </StyledCard>
            </Box>
    );
}