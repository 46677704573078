import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Button,
    TextField,
    Checkbox,
    Avatar,
    Autocomplete,
    Grid,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Tooltip,
} from '@mui/material';
import {
    Search as SearchIcon,
    Logout as LogoutIcon,
    Block as BlockIcon,
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
} from '@mui/icons-material';
import { useTwitchAPI } from '../../hooks/useTwitchApi';
import { useDebounce } from '../../hooks/useDebounce';
import { StyledCard, StyledCardContent, Logo, ScrollableBox } from './LoggedInPage.styles';
import {banFromMultipleChannels} from "../../api/twitchAPI";
import {IChat, ISearchChannelsResponse} from "../../common/interfaces";
const LoggedInPage: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedUser, setSelectedUser] = useState<ISearchChannelsResponse | null>(null);
    const [reason, setReason] = useState<string>('');
    const navigate = useNavigate();


    const {
        channelInfo,
        chats,
        setChats,
        fetchChannelInfo,
        fetchModeratedChannels,
        searchChannels,
    } = useTwitchAPI();

    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    useEffect(() => {
        fetchChannelInfo();
    }, [fetchChannelInfo]);

    useEffect(() => {
        if (channelInfo.id) {
            fetchModeratedChannels();
        }
    }, [channelInfo, fetchModeratedChannels]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            searchChannels(debouncedSearchTerm).then(setSuggestions);
        } else {
            setSuggestions([]);
        }
    }, [debouncedSearchTerm, searchChannels]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleToggle = (value: string) => () => {
        const newChats: Array<IChat> = chats.map((chat) =>
            chat.name === value ? { ...chat, checked: !chat.checked } : chat
        );
        setChats(newChats);
    };

    const handleSelectAll = () => {
        setChats(chats.map(chat => ({ ...chat, checked: true })));
    };

    const handleUnselectAll = () => {
        setChats(chats.map(chat => ({ ...chat, checked: false })));
    };

    const handleBan = async () => {
        if (!reason.trim()) {
            alert("Please provide a reason for banning.");
            return;
        }

        const selectedChannels = chats.filter(channel => channel.checked);
        await banFromMultipleChannels(selectedChannels, selectedUser, reason, channelInfo);
    };

    const handleLogout = () => {
        localStorage.removeItem("twitch_token");
        navigate('/');
    };

    return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    padding: 2,
                    bgcolor: 'background.default',
                }}
            >
                <StyledCard>
                    <StyledCardContent>
                        <Box sx={{ textAlign: 'center', mb: 4 }}>
                            <img src={'logo.png'} alt="Crossbanhammer Logo" style={{ maxWidth: '50%', height: 'auto' }} />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                            <Avatar sx={{ width: 48, height: 48, mr: 2 }} src={channelInfo.avatar} alt={channelInfo.name}>
                                {channelInfo.name.charAt(0)}
                            </Avatar>
                            <Typography variant="h5" sx={{ flexGrow: 1 }}>
                                {channelInfo.name}
                            </Typography>
                            <Button
                                variant="outlined"
                                startIcon={<LogoutIcon />}
                                onClick={handleLogout}
                                color="secondary"
                            >
                                Logout
                            </Button>
                        </Box>

                        <Autocomplete
                            fullWidth
                            options={suggestions}
                            getOptionLabel={(option: any) => option.display_name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Search User *"
                                    required
                                    onChange={handleSearch}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            renderOption={(props, option: any) => (
                                <li {...props}>
                                    <Avatar src={option.thumbnail_url} sx={{ mr: 2 }}>
                                        {option.display_name.charAt(0)}
                                    </Avatar>
                                    <Typography>{option.display_name}</Typography>
                                </li>
                            )}
                            onChange={(event, newValue) => {
                                setSelectedUser(newValue);
                            }}
                            sx={{ mb: 4 }}
                        />

                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Available Chats
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            <Tooltip title="Select All">
                                <IconButton onClick={handleSelectAll} color="primary">
                                    <CheckCircleIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Unselect All">
                                <IconButton onClick={handleUnselectAll} color="secondary">
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <ScrollableBox>
                            <Grid container spacing={2}>
                                {chats.map((chat) => (
                                    <Grid item xs={12} sm={6} md={3} key={chat.name}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={chat.checked}
                                                    onChange={handleToggle(chat.name)}
                                                    size="small"
                                                />
                                            }
                                            label={
                                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                    <Avatar src={chat.avatar} sx={{ width: 24, height: 24, mr: 1, flexShrink: 0 }}>
                                                        {chat.name.charAt(0)}
                                                    </Avatar>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            flexGrow: 1,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        {chat.name}
                                                    </Typography>
                                                </Box>
                                            }
                                            sx={{
                                                margin: 0,
                                                width: '100%',
                                                '& .MuiFormControlLabel-label': {
                                                    width: '100%',
                                                },
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </ScrollableBox>

                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Ban Reason"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            sx={{ mb: 1 }}
                            required
                        />
                        <Typography variant="body2"  sx={{ mb: 2 }}>This user was crossBanHammered by {channelInfo.name} for: {reason}</Typography>
                        <Button
                            fullWidth
                            variant="contained"
                            color="error"
                            startIcon={<BlockIcon />}
                            sx={{ py: 2 }}
                            onClick={handleBan}
                            disabled={!selectedUser || !reason.trim()}
                        >
                            Ban User
                        </Button>
                    </StyledCardContent>
                </StyledCard>
            </Box>
    );
};

export default LoggedInPage;