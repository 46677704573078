import axios from 'axios';
import {
    IAvatar,
    IChat,
    IFetchChannelInfoResponse,
    IResponseChannelInfo,
    IResponseModeratedChannel, ISearchChannelsResponse
} from "../common/interfaces";

const API_BASE_URL = 'https://api.twitch.tv/helix';
const CLIENT_ID = process.env.REACT_APP_TWITCH_CLIENT_ID || '';

const getHeaders = () => ({
    'Authorization': `Bearer ${localStorage.getItem("twitch_token")}`,
    'Client-Id': CLIENT_ID,
});

// Utility function to delay between requests (for rate limiting)
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Utility function to monitor rate limits and wait if near the limit
const handleRateLimit = async (headers: any) => {
    const remainingRequests = parseInt(headers['ratelimit-remaining'], 10);
    const resetTime = parseInt(headers['ratelimit-reset'], 10) * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    const waitTime = resetTime - currentTime;

    if (remainingRequests <= 1) {
        console.log(`Rate limit reached, pausing for ${waitTime / 1000} seconds.`);
        await delay(waitTime); // Wait until the rate limit resets
    }
};

// Dynamically calculate batch size based on remaining quota
const calculateBatchSize = (remainingRequests: number): number => {
    return Math.max(1, Math.floor(remainingRequests / 4)); // Use 1/4 of remaining quota, but ensure at least 1
};

export const fetchChannelInfo = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/users`, { headers: getHeaders() });
        await handleRateLimit(response.headers);  // Check rate limits

        const { login, profile_image_url, id } = response.data.data[0];
        return { name: login, avatar: profile_image_url, id };
    } catch (error) {
        console.error("Error fetching channel info:", error);
        return { name: '', avatar: '', id: '' };
    }
};

export const fetchModeratedChannels = async (userId: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/moderation/channels`, {
            headers: getHeaders(),
            params: { user_id: userId }
        });
        await handleRateLimit(response.headers);  // Check rate limits

        return response.data.data.map((channel: IResponseModeratedChannel) => ({
            name: channel.broadcaster_name,
            channelId: channel.broadcaster_id,
            checked: false,
            avatar: `/placeholder.svg?height=50&width=50`
        }));
    } catch (error) {
        console.error("Error fetching moderated channels:", error);
        return [];
    }
};

export const fetchChannelAvatars = async (channels: Array<IChat>) => {
    try {
        const ids = channels.map(channel => channel.channelId).join('&id=');
        const response = await axios.get(`${API_BASE_URL}/users?id=${ids}`, { headers: getHeaders() });
        await handleRateLimit(response.headers);  // Check rate limits

        const users: Array<IResponseChannelInfo> = response.data.data;
        const avatarMap = users.reduce((acc: IAvatar, user) => {
            acc[user.id] = user.profile_image_url;
            return acc;
        }, {});
        return channels.map(channel => ({
            ...channel,
            avatar: avatarMap[channel.channelId] || `/placeholder.svg?height=50&width=50`,
        }));
    } catch (error) {
        console.error("Error fetching channel avatars:", error);
        return channels;
    }
};

export const searchChannels = async (searchTerm: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/search/channels`, {
            headers: getHeaders(),
            params: { query: searchTerm }
        });
        await handleRateLimit(response.headers);  // Check rate limits

        return response.data.data;
    } catch (error) {
        console.error("Error fetching user suggestions:", error);
        return [];
    }
};

// Banning from multiple channels with optimized rate-limiting and dynamic delay
export const banFromMultipleChannels = async (
    selectedChannels: Array<IChat>,
    selectedUser: ISearchChannelsResponse | null,
    reason: string,
    channelInfo: IFetchChannelInfoResponse
) => {
    const token = localStorage.getItem("twitch_token");
    const formattedReason = `This user was crossBanHammered by ${channelInfo.name} for: '${reason}'`;

    try {
        // Fetch initial rate limit info before starting
        let remainingRequests = 800; // Twitch allows 800 requests per minute by default
        let resetTime = Date.now() + 60 * 1000; // Default reset time is 1 minute from now

        for (let i = 0; i < selectedChannels.length; i++) {
            const batchSize = calculateBatchSize(remainingRequests);
            const batch = selectedChannels.slice(i, i + batchSize);

            const batchRequests = batch.map(channel => {
                return axios.post(
                    `${API_BASE_URL}/moderation/bans?broadcaster_id=${channel.channelId}&moderator_id=${channelInfo.id}`,
                    {
                        data: {
                            user_id: selectedUser?.id,
                            reason: formattedReason,
                        },
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Client-Id": CLIENT_ID,
                            "Content-Type": "application/json",
                        },
                    }
                );
            });

            try {
                const responses = await Promise.all(batchRequests);
                remainingRequests = parseInt(responses[0].headers['ratelimit-remaining'], 10);
                resetTime = parseInt(responses[0].headers['ratelimit-reset'], 10) * 1000;
                await handleRateLimit(responses[0].headers);  // Check rate limits
                console.log(`Banned from batch of ${batch.length} channels`);
            } catch (error) {
                console.error(`Error banning in batch:`, error);
            }

            // Dynamic delay based on reset time and remaining requests
            const currentTime = Date.now();
            const waitTime = Math.max(0, resetTime - currentTime); // Wait for reset if necessary
            if (remainingRequests <= 1) {
                console.log(`Waiting for ${waitTime / 1000} seconds to reset the rate limit...`);
                await delay(waitTime);
            }
        }

        console.log('User banned from all selected channels.');
    } catch (error) {
        console.error('Error banning user from channels:', error);
    }
};
