import { useState, useCallback } from 'react';
import { fetchChannelInfo, fetchModeratedChannels, fetchChannelAvatars, searchChannels } from '../api/twitchAPI';
import {IChat, IFetchChannelInfoResponse} from "../common/interfaces";

export function useTwitchAPI() {
    const [channelInfo, setChannelInfo] = useState<IFetchChannelInfoResponse>({ name: '', avatar: '', id: '' });
    const [chats, setChats] = useState<Array<IChat>>([]);

    const fetchChannelInfoCallback = useCallback(async () => {
        const info = await fetchChannelInfo();
        setChannelInfo(info);
    }, []);

    const fetchModeratedChannelsCallback = useCallback(async () => {
        if (channelInfo.id) {
            const moderatedChannels: Array<IChat> = await fetchModeratedChannels(channelInfo.id);
            setChats(moderatedChannels);
            if (moderatedChannels.length > 0) {
                const updatedChats = await fetchChannelAvatars(moderatedChannels);
                setChats(updatedChats);
            }
        }
    }, [channelInfo.id]);

    return {
        channelInfo,
        chats,
        setChats,
        fetchChannelInfo: fetchChannelInfoCallback,
        fetchModeratedChannels: fetchModeratedChannelsCallback,
        searchChannels,
    };
}