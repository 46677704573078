import { Card, CardContent, Typography, Box, styled } from '@mui/material';

export const StyledCard = styled(Card)`
  width: 100%;
  max-width: 800px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.background.paper}; // theme.palette now recognized
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
`;

export const StyledCardContent = styled(CardContent)`
  padding: ${({ theme }) => theme.spacing(4)}; // theme.spacing now recognized
`;

export const Logo = styled(Typography)`
  font-weight: bold;
  background: linear-gradient(45deg, ${({ theme }) => theme.palette.primary.main}, ${({ theme }) => theme.palette.secondary.main});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ScrollableBox = styled(Box)`
  height: 250px;
  overflow-y: auto;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  width: 100%;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 4px;
  }
`;
