import {Button, Card, CardContent, Typography, styled} from "@mui/material";

// Styled components
export const StyledCard = styled(Card)`
  width: 100%;
  max-width: 400px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
`;

export const StyledCardContent = styled(CardContent)`
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const Logo = styled(Typography)`
  font-weight: bold;
  background: linear-gradient(45deg, ${({ theme }) => theme.palette.primary.main}, ${({ theme }) => theme.palette.secondary.main});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const TwitchButton = styled(Button)`
  background-color: #9146FF;
  font-weight: bold;
  font-size: 1.3rem;
  text-transform: none;
  padding: ${({ theme }) => theme.spacing(1.5, 3)};
  border-radius: 8px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #7F3FD9;
    box-shadow: 0 4px 12px rgba(145, 70, 255, 0.4);
  }
`;

export const TwitchIcon = styled('img')`
  width: 32px;
  height: 32px;
`;